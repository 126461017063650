.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    vertical-align: middle;
}

a {
    cursor: pointer;
}
#ticker{
    line-height: 36px;
}
.selector-loadingindicator {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("../../../../public/img/bullet/loading.gif") center center no-repeat;
    background-color: white;
    z-index: 990;
    -khtml-opacity: 0.6;
    -moz-opacity: 0.6;
    -ms-filter: "alpha(opacity=60)";
    filter: alpha(opacity=60);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0.6);
    opacity: 0.6;
}

.dropdown ul a {
    text-align: center;
}

.ricbar {
    position: relative;
    width: 100%;
    margin-top: 18px;
    height: 36px;
}

#livematrix .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#livematrix .tablist .tablist-item:focus,
#livematrix .tablist .tablist-item:hover {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#livematrix .tablist {
    position: relative;
    height: 60px;
    margin-top: 20px;
}

#livematrix .tablist-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#livematrix .tablist-left table {
    table-layout: fixed;
}

#livematrix .tablist-left table td {
    border-right: 5px solid #fff;
}

#livematrix .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}

#livematrix .tablist-item {
    width: 130px;
    height: 55px;
    display: table;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid #E0E0E0;
    background-color: #EEF0F2;
    cursor: pointer;
}

#livematrix .tablist-left table td:last-child {
    border-right: 0;
}

#livematrix .tablist .active .tablist-item {
    background-color: #FECC0A;
    border-bottom: 5px solid #333;
}

#livematrix .tablist-item-txt {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    line-height: normal;
    padding: 0 5px;
}

#livematrix #minidwcalc table tr>td:last-child {
    width: 50%;
}

#livematrix #minidwcalc table,
#livematrix #minidwcalc table input {
    width: 100% !important;
}

/* #livematrix #minidwcalc .slider.slider-horizontal {
    width: 100% !important;
} */

#livematrix #minidwcalc .hidden-xs,
#livematrix #minidwcalc .hidden-sm {
    display: table !important;
}

#livematrix .chart-legend-bar.warrant {
    background-color: #F7941E;
}

#livematrix .chart-legend-left,
#livematrix .chart-legend-right {
    text-align: center;
}

#livematrix .chart-legend-bar.underlying {
    background-color: #097CCF;
}

#livematrix .chart-legend-bar {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 3px;
    bottom: 4px;
    margin-left: 5px;
}

#livematrix .ctab-item {
    text-align: center;
    width: 25%;
    border: 2px solid #FECC0A !important;
    height: 33px;
    cursor: pointer;
}

#livematrix .ctab-item.active {
    background-color: #FECC0A;
    color: #000;
}

#livematrix .ctab-item-space {
    width: 12px;
}

.ricbar-lbl {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    right: 280px;
    background-color: #DFDBCF !important;
}

.ricbar-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    bottom: 0;
}

.lmtabcontainer {
    padding: 0;
}

.lmtab {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #FECC0A !important;
    line-height: 25px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

.lmtab.pressed {
    background-color: #FECC0A;
}

#lmtablecontainer {
    /* min-height: 150px; */
}

.lbtable,
.lbtable thead th {
    border-bottom: 1px solid #FECC0A;
    text-align: center;
    margin-bottom: 0px;
}

#five_day_table>tbody>tr>td.subtable {
    width: 20%;
    font-size: 13px;
    padding: 0;
    vertical-align: top;
    border-left: 1px solid #ddd;
}

#five_day_table>tbody>tr>td:first-child {
    border-left: 0;
}

#five_day_table tbody th {
    /* font-family: "HelveticaNeueMedium"; */
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    padding: 8px 0;
}

#five_day_table table.innertable td {
    text-align: center;
    padding: 8px 0;
}

.icon-refresh:after {
    /* font-family: 'FontAwesome'; */
    content: "\f0e2";
}

.color-tip {
    width: 170px;
}

.color-tip>div {
    display: inline-block;
    height: 18px;
    line-height: 18px;
}

.color-tip .color-icon {
    width: 18px;
}

.color-tip .tip-text {
    margin-left: 20px;
}

#last_update {
    /*    float: right;*/
    margin-bottom: 16px;
}

#srtable {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

#srtable>table {
    margin-bottom: 0;
    white-space: nowrap;
}

#srtable .pricetbl {
    margin-bottom: 16px;
}

#srtable .pricetbl.tableSize td,
#srtable .pricetbl.tableSize th {
    font-size: 16px;
}

#srtable .pricetbl tr th,
#srtable .pricetbl tr td {
    text-align: left;
}

#srtable .srtable-leftlbl {
    width: 30%;
}

#srtable .srtable-leftval {
    width: 20%;
}

#srtable .srtable-rightlbl {
    width: 30%;
}

#srtable .srtable-rightval {
    width: 20%;
}

#srtable table tbody tr>td {
    white-space: nowrap;
}

.table-left tr:nth-of-type(even) {
    background-color: transparent !important;
}

.table-left tr:nth-of-type(odd) {
    background-color: #F3F3F4 !important;
}

.table-left td,
.table-left th {
    text-align: left;
    font-size: 16px;
}

/* #srtable table tbody tr>td:nth-child(2){
    border-right: 20px solid #FFF;
} */
#srtable .markethourtbl {
    margin-top: 16px;
    margin-bottom: 16px;
}

#srtable .markethourtbl th {
    font-weight: normal;
    /* font-family: "HelveticaNeueMedium"; */
}

.skipholiday {
    background-color: #E4AC76 !important;
}

.hidematrix {
    background-color: #F8D98A !important;
}

#lmatrix_tbl_f {
    font-size: 11px;
}

#lmatrix_tbl_f hr {
    margin-bottom: 10px;
    border-top: 1px solid #ddd;
}

#lmatrix_tbl_f ol {
    margin-left: -20px;
}

#lmatrix_tbl_f li {
    margin-bottom: 10px;
}

#orientationcontainer {
    display: none;
    font-size: 12px;
    padding: 8px 0;
    text-align: center;
    background-color: #F3F3F4;
}

#defination .panel-heading {
    background-color: #666666;
    padding: 4px 10px;
}

#defination .panel-title {
    font-size: 14px;
}

#defination .glyphicon {
    color: white;
}

#defination #collapse_dwcompare3 {
    font-size: 12px;
    padding: 10px;
}

td.text-content {
    background-color: #F3F3F4;
}

#top_area {
    margin-bottom: 37px;
}

#lmtablecontainer tr.midpoint,
#lmtablecontainer tr.midpoint.bgcolor-08 {
    background-color: rgba(9, 124, 207, 0.2) !important;
}

#lmtablecontainer tr.midpoint.sp500 {
    background-color: inherit;
}

#lmtablecontainer tr.midpoint.sp500.bgcolor-08 {
    background-color: #F5F6F7 !important;
}

.qtip {
    max-width: 600px;
}

#viewwarrantterms {
    /* width: 170px; */
    /* right: 120px; */
}

#lastview table tr.striped {
    background-color: #F5F6F7;
}

.printimg {
    width: 20px;
    margin-top: -8px;
    cursor: pointer;
}

#print_content {
    display: none;
}

/*******************************************************************************
 livematrix section combobox
*******************************************************************************/
.combobox {
    position: relative;
}

.combobox-toggle {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 36px;
    padding: 0;
    cursor: pointer;
}

.combobox-toggle,
.combobox-toggle:focus,
.combobox-toggle:hover,
.combobox-toggle:visited {
    color: #000;
}

.combobox-input {
    margin: 0;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}

.combobox-toggle-icon {
    margin-top: 10px;
    margin-right: 12px;
}

.ui-widget {
    /* font-family: 'HelveticaNeueLight', 'HelveticaNeue', 'Roboto', Arial, sans-serif; */
    font-size: 14px;
}

.ui-menu .ui-menu-item {
    text-align: center;
}

.ui-menu .ui-menu-item a.ui-state-hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #f5f5f5;
}

.ui-menu .ui-menu-item a:visited,
.ui-menu .ui-menu-item a:focus,
.ui-menu .ui-menu-item a:hover {
    color: #262626
}

.ui-autocomplete.ui-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

/*******************************************************************************
 How to get started
*******************************************************************************/
.page-header {
    display: flex;
    justify-content: space-between;
}

.tradeDropDown {
    position: absolute;
    border: 1px solid #000000;
    text-align: center;
    z-index: 9;
    background: #ffffff;
    width: 370px;
    padding: 10px 0;
}

.tradeDropDown a {
    margin-bottom: 5px;
}

.dropdown-or {
    display: flex;
}

.dropdown-or hr {
    width: 40%;
    margin-top: 10px;
    margin-bottom: 0px;
}

.dropdown-or p {
    margin: 0 0 5px;
}

.selectedTrade,
.selectedTrade:hover,
.selectedTrade:focus {
    background-color: #999999 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .btn-primary:hover,
.tradeDropDown .btn-primary:focus {
    background-color: #000000 !important;
    color: #FFFFFF !important;
    border-color: #888888 !important;
}

.tradeDropDown .broker_link {
    margin-bottom: 5px;
    width: 45%;
}
.visible-pc,
.visible-minpc{
    display: none;
}
.isShowMobile{
    display: none;
}
.isShowPC{
    display: revert;
}

#livematrix #dailyhsi .news-title{
    font-family: 'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif;
    font-size: 16px;
    line-height: 1.42857143;
}
#livematrix #dailyhsi .news-summary{
    font-size: 16px;
    line-height: normal;
}

/*******************************************************************************
 Responsive @media
*******************************************************************************/
/* Extra small devices (phones, less than 768px) */
@media screen and (max-width: 991px) {

    .tradeDropDown {
        right: 20px;
    }
    .ttip_con{
        width: 70%;
    }
    #priceTable .react-datepicker-popper{
        left: -7px !important;
        right: unset !important;
    }
    #priceTable .react-datepicker__triangle{
        left: 25px !important;
    }
    .chart-legend-tbl tr td:nth-child(1){
        text-align: left;
    }
}

@media screen and (max-width: 767px) {

    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
        padding-left: 6px;
        padding-right: 6px;
    }
    #livematrix .tablist-item{
        width: auto;
    }
    #lmtablecontainer table *,
    #srtable table * {
        font-size: 11px;
    }

    #srtable table tbody tr>td:nth-child(2) {
        border-right: 0;
    }

    #srtable>table th,
    #srtable>table td {
        padding: 2px;
    }

    h3 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .lmtab {
        margin-left: 0;
        margin-right: 0;
    }

    .ricbar {
        height: 25px;
    }

    #livematrix .ricbar .ricbar-btn {
        /* font-family: HelveticaNeueLight; */
        width: 60px;
        padding: 0;
    }

    #livematrix .ricbar .ricbar-lbl {
        font-size: 14px;
        text-align: center;
        right: 210px;
        overflow: hidden;
        line-height: 25px;
        width: 100%;
    }

    .ricbar-home {
        /* font-family: "HelveticaNeueMedium"; */
        font-size: 20px;
    }

    .ticker-home {
        padding-top: 2px;
    }

    .btnbar {
        position: relative;
    }

    .btnbar .ricbar-btn {
        margin-top: -5px;
        height: 25px;
        padding-top: 1px;
    }

    #viewwarrantterms {
        /* right: 100px; */
        /* width: 140px; */
    }

    .qtip {
        max-width: 400px;
    }

    #pageHeader .tradeDropDown {
        width: 350px;
    }
    .isShowPC{
        display: none !important;
    }
    .isShowMobile{
        display: revert !important;
    }
}

@media screen and (max-width: 767px) and (orientation:portrait) {

    /* Style adjustments for portrait mode goes here */
    .hide-xs-portrait {
        display: none !important;
    }

    #orientationcontainer {
        display: block;
    }

    .color-tip {
        width: 130px;
    }

    .color-tip .tip-text {
        margin-left: 5px;
    }

    /*    #last_update{
        float: left;
        margin-top: 10px;
    }*/
    .qtip {
        max-width: 280px;
    }
}

@media screen and (min-width: 992px) {
    .ricbar-lbl {
        font-size: 16px;
        text-align: center;
        padding-top: 5px;
        line-height: 28px ;
    }
    .visible-pc{
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    .ricbar-lbl {
        font-size: 20px;
        text-align: left;
        padding-top: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 850px) {
    .visible-pc{
        display: block;
    }
}

@media screen and (min-width: 992px) and (max-width: 1080px) {
    .visible-minpc{
        display: block;
    }
}