#hedging_tool .react-datepicker-wrapper{
    width:22%;
    font-size: 12px;
}
#hedging_tool .tool_Picker .react-datepicker-wrapper{
    width:80%;
}

#hedging_tool_breakeven .react-datepicker-wrapper{
    font-size: 12px;
}
#hedging_tool .slider.slider-horizontal{
    width:50%;
    margin-left:10px
}
#hedging_tool th{
    color:#000!important;
    font-size: 14px;
    background-color: #fff!important;
}
#hedging_tool tr th:nth-child(2){
    border-right:1px solid #000;
}
#hedging_tool .tool_Slider .slider.slider-horizontal{
    width: 80%;
    margin-top:25px;
}
#hedging_tool .tool_Slider2 .slider.slider-horizontal{
    width: 80%;
}
#ivtable .react-datepicker__input-container input{
    text-align: left!important;
}
#hedging_tool.table-closed>tbody tr:first-child,#hedging_tool.table-closed tbody tr:last-child {
    border:0px;
}
@media screen and (max-width: 767px){
    #hedging_tool th{
        font-size: 12px!important;
    }
    #hedging_tool .hedging_tool_inpu ,#hedging_tool .underlying_bid_price{
       width: 100%!important;
       margin-left: 0px!important;
    }
    #hedging_tool .slider.slider-horizontal,#hedging_tool_breakeven  .slider.slider-horizontal{
        width:100%;
        margin-left: 0px;
        margin-top: 10px;
    }
    #hedging_tool .react-datepicker-wrapper{
        width:100%;
    }
    #hedging_tool .react-datepicker-wrapper{
        font-size: 10px;
    }
    #hedging_tool_breakeven .react-datepicker-wrapper{
        font-size: 10px;
    }
}